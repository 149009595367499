import React from 'react';

const loadingPanelComponent = props => {
  return(
      <div className="loading-panel">
        <img src="/images/sparc-spinner.gif" alt="Working . . ."/>
      </div>
  );
};

export default loadingPanelComponent;