import React from 'react';

const Spinner = props => {
  return (
      <div className="spinnerOverlay">
        <img src="/images/sparc-spinner.gif" alt="Working . . ."/>
      </div>
  );
};

export default Spinner;